.team-block {
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    margin: 8px;
}

.team-block img {
    width: 100%;
    height: 70%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.team-block .text-container {
    text-align: center;
    padding: 8px 0;
    height: 30%;
}

.team-block h3 {
    margin: 4px 0;
    font-size: 1.1rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.team-block h4 {
    margin: 4px 0;
    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* .team-row {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
} */

.member-icon-button {
    color: white;
    width: 40px;
    height: 40px;
}

/* Modal Styles */
.modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: #303C55;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 700px;
    max-height: 95vh;
    border-radius: 10px;
    position: relative;
    text-align: center;
    overflow: auto;
}


.modal-content img {
    max-width: 100%;
    max-height: 60vh;
    object-fit: contain;
    border-radius: 8px;
}

.modal-content h3 {
    font-size: 1.5rem;
    color: white;
    margin-top: 1rem;
}

.modal-content h4 {
    font-size: 1.25rem;
    color: white;
    margin-top: 0.5rem;
}

.modal-content p {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1rem;
    color: white;
    margin-top: 1rem;
    line-height: 1.5;
    text-align: justify;
}

.close {
    color: white;
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus,
.icon:hover,
.icon:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}