/* In your styles/index.css or a separate CSS file */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left {
    animation: slideInLeft 0.5s ease-in-out forwards;
}

.slide-in-right {
    animation: slideInRight 0.5s ease-in-out forwards;
}

.nav-button {
    padding: 10px;
}

.nav-button:hover {
    background-color: white;
    color: #303C55;
    border-radius: 10px;
    padding: 10px;
}