.contact-button{
    background-color: #f5f5f5;
    color: #0e1011;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 8px;
}

.contact-button:hover {
    background-color: #00FFFF;
    color: #303C55;
    font-size: 1rem;
    cursor: pointer;
    transform: translateY(-10px);
    border-radius: 8px;
}

.map-container{
    width: 100%;
    height: 500px;
    margin-top: 2%;
    background-color: #303C55;
}

.follow-us-link {
    background-color: #f5f5f5;
    color: #000000;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
}

.follow-us-link:hover {
    background-color: #e0e0e0;
}