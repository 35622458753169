/* Increase the size of calendar cells */
.react-calendar__tile {
    height: 120px;
    max-width: 100%;
    text-align: left;
    padding: 8px;
    box-sizing: border-box;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #303C55;
    /* theme color */
    color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* Style for event details inside the date box */
.event-details {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2;
    color: #303C55;
    /* Default color */
}

/* Style for event details when the date is selected */
.react-calendar__tile--active .event-details {
    color: white;
    /* Change color when selected */
}

/* Highlight selected date */
.react-calendar__tile--active {
    background-color: #e6f7ff;
}

.react-calendar__tile--active:hover {
    background-color: #d4e8ff;
}

/* Adjust the overall size of the calendar */
.react-calendar {
    width: 100% !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* Your custom color for the selected tile */
.react-calendar__tile--active {
    background-color: #303C55 !important;
    /* Replace with your custom color */
    color: white !important;
    /* Ensure text color is readable */
}

/* Optional: Customize hover or focus states if needed */
/* .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
} */

/* Optional: Customize the current date highlight if needed */
.react-calendar__tile--now {
    background: #00FFFF !important;
    /* Replace with your custom color */
    color: white;
}

/* Add this to your Event.css */
.event-image {
    max-width: 100%;
    /* Ensure the image fits within its container */
    height: auto;
    max-width: 40%;
    /* Adjust this value as needed */
    border: 4px solid white;
}

/* Style for the navigation label button */
.react-calendar__navigation__label {
    background-color: transparent;
    /* Default background */
    color: #ffffff;
    /* Default text color */
    border-radius: 8px;
    padding: 8px;
    /* Add padding for spacing */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    /* Smooth transitions */
    text-align: center;
    /* Center align text */
}

/* Hover state for the navigation label button */
.react-calendar__navigation__label:hover {
    background-color: #00FFFF;
    /* Background color on hover */
    color: #303C55;
    /* Text color on hover */
    border-color: #303C55;
    /* Change border color on hover */
}

/* Focus state for the navigation label button */
.react-calendar__navigation__label:focus {
    outline: none;
    /* Remove default outline */
    background-color: #00FFFF;
    /* Background color on focus */
    color: #303C55;
    /* Text color on focus */
    border-color: #303C55;
    /* Change border color on focus */
}

.react-calendar__navigation__arrow {
    border-radius: 8px;
    height: 35px;
}

.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__arrow:focus {
    color: #303C55;
}

.star-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    position: relative;
    /* Needed for proper overlay */
}

.star {
    width: 100px;
    height: 60px;
    background-color: black;
    clip-path: polygon(50% 0%,
            61% 35%,
            98% 35%,
            68% 57%,
            79% 91%,
            50% 70%,
            21% 91%,
            32% 57%,
            2% 35%,
            39% 35%);
    position: relative;
    display: inline-block;
}

.star-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    z-index: 1;
    /* Ensure it is on top of the star */
    white-space: nowrap;
}