@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.about-init {
    background: rgb(138, 43, 226);
    background: linear-gradient(180deg, rgba(138, 43, 226, 1) 29%, rgba(73, 16, 139, 1) 58%);
    color: #F3F8FF
}

.about-container {
    color: black;

}

.about-info {
    height: fit-content;
}

.offer-container {
    background: #303C55;
}

/* .offer-head {
    margin-top: 10%;
} */
@media (max-width: 610px) {
    .offer-head {
        margin-top: 10%;
    }
}

#about {
    color: black;
    height: fit-content;
}

.section1 {
    height: fit-content;
    color: black;
    font-family: "Poppins", sans-serif;
}

.list-outside {
    display: flex;

    align-items: center;
    justify-content: center;
}

.hist2 {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;

}

.whoimg {
    width: 40px;
}

.grid1 {
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 90vw;
    flex: wrap;


}

.grid2 {
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 90vw;
    flex: wrap;

}

.relative {
    margin-top: 30px;
}

.statistics {
    text-align: center;
    margin-top: 20px;
    color: white;
    margin-top: 100px;
}




.supacell {
    display: flex;
    width: 30%;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.perc-head {
    margin-top: 40px;
    text-align: center;
}

.percs {
    width: 100%;
    height: auto;
    display: flex;
    flex: wrap;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .statistics {
        margin-top: 150px;
        text-align: center;
    }

    .first {
        font-size: 30px;
    }

    .first-1 {
        font-size: 20px;
    }

    .slideshow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60vw;
        height: 100%;
    }

    .midsec {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .chapters {
        justify-content: start;
    }

    .percs {
        text-align: center;

    }

    .supacell {
        width: 80%;
    }

    .hist2 {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
    }

    .about-info {
        text-align: justify;
    }

    .grid1 {
        flex-direction: column;
        width: 100vw;
        flex: wrap;
        text-align: center;
    }

    .grid2 {
        flex-direction: column;
        width: 100vw;
        flex: wrap;
        text-align: center;
    }

}


.endstuff {
    margin-bottom: 40px;
}

.history-header {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}

.get-involved {
    margin-left: 10%;
    margin-right: 10%;
}