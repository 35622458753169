@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(225, 225, 225);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.intro-bg {
  background-image: url('../../public/images/mainbg.png');
  filter: blur(2px);
  -webkit-filter: blur(2px);
  height: 500px;
}

.intro-container {
  background-color: rgba(0, 0, 0, 0.6);
  height: 500px;
}

@media only screen and (max-width: 500px) {
  .intro-header {
    font-size: x-large;
    margin-top: 15px;
    width: 100%;
  }

  .intro-subheader {
    font-size: medium;
    color: rgb(255, 51, 0);

    text-decoration: underline black;
    text-decoration-thickness: 3px;
  }

}

html {
  scroll-behavior: smooth;
}

section {
  scroll-margin-top: 60px;
  /* padding-bottom: 4rem; */
}

.page-header {
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 1.5rem;
  font-weight: medium;
  color: #00FFFF;
  font-family: "Poppins", sans-serif;
  text-align: center;
  background-color: #303C55;
}